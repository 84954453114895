<template>
    <div>
        <nl-loader v-show="preloading" :show="true" />

        <div v-show="!preloading">
            <b-loading v-model="isLoading" :is-full-page="true" />

            <section class="box">
                <b-tabs
                    v-model="activeTab"
                    @input="
                        value => {
                            openOnce[value] = true
                        }
                    "
                >
                    <b-tab-item
                        v-for="(tab, key) in tabs"
                        :key="`sites_${key}`"
                        :label="`${tab.label} (${counts[tab.type] || 0}) `"
                        :icon="tab.icon"
                        :value="tab.type"
                        :disabled="counts[tab.type] <= 0"
                    >
                        <PartnerSiteTable
                            v-if="openOnce[key]"
                            :status="tab.status"
                            :force-update="forceUpdate"
                            :left-buttons="leftButtons"
                            :tab-info="tab"
                            :groups="formattedGroups"
                            @openModal="openModalFromTable"
                            @editSite="editSite"
                            @rescrap="scrap"
                            @deleteBulkSites="deleteBulkSites"
                            @desactivateBulkSites="disableBulkSites"
                            @reactivateBulkSites="reactivateBulkSites"
                            @redactionBulkSites="redactionBulkSites"
                            @maxWordsBulkSites="maxWordsBulkSites"
                            @setAcceptAutoBulkSites="setAcceptAutoBulkSites"
                            @setAcceptAutoPreorderBulkSites="setAcceptAutoPreorderBulkSites"
                        />
                    </b-tab-item>
                </b-tabs>
            </section>
        </div>

        <PartnerSiteModal
            ref="siteModal"
            :data="form"
            :groups="formattedGroups"
            @cleanForm="resetForm"
            @saveSite="saveSite"
            @editSite="editSite"
        />
    </div>
</template>

<script>
    import PartnerSiteTable from '@/components/PartnerSites/PartnerSiteTable.vue'
    import { mapActions, mapState } from 'vuex'
    import PartnerSiteModal from '@/components/PartnerSites/PartnerSiteModal.vue'

    export default {
        name: 'PartnerSites',
        components: { PartnerSiteModal, PartnerSiteTable },
        title: 'partenaire-site.title',
        data() {
            return {
                wordpress_url_plugin: '',
                sites: [],
                alerts: {},

                form: {},

                formattedGroups: [],

                activedSites: [],
                deletedSites: [],
                waitingActivationSites: [],
                desactivatedSites: [],
                refusedSites: [],
                waitingImprovementSites: [],

                preloading: true,
                isLoading: false,

                // Status
                statusActive: 1,
                statusWaitingActivation: -1,
                statusDesactiveNL: -2,
                statusRefuse: -3,
                statusDelete: 0,
                statusDesactivePartner: -4,
                statusWaitingImprove: -5,

                // Tabs
                activeTab: 0,

                // Total per tabs
                counts: {},

                // Store witch tab has been open
                openOnce: {
                    0: true
                },

                // Force table to refresh data
                forceUpdate: false,

                // Table top-left buttons
                leftButtons: [
                    {
                        label: this.$t('partenaire-site.addawebsite'),
                        icon: 'plus-circle',
                        type: 'is-warning',
                        action: () => this.openModal()
                    },
                    {
                        label: this.$t('partenaire-site.download_wp_plugin'),
                        icon: 'wordpress',
                        iconPack: 'fab',
                        type: 'is-info',
                        action: () => window.open(this.wordpress_url_plugin, '_blank')
                    }
                ]
            }
        },
        computed: {
            ...mapState('group', {
                groups: state => state.groups
            }),
            tabs() {
                return [
                    {
                        type: 'all',
                        label: this.$t('global.all'),
                        icon: 'sitemap',
                        status: null
                    },
                    {
                        type: 'waiting_activation',
                        label: this.$t('status.attentevalidation'),
                        icon: 'pause-circle',
                        status: -1
                    },
                    {
                        type: 'waiting_improvement',
                        label: this.$t('partenaire-site.attenteamelioration'),
                        icon: 'briefcase',
                        status: -5,
                        alert: this.alerts.improvement
                    },
                    {
                        type: 'activated',
                        label: this.$t('status.activated-s'),
                        icon: 'check-circle',
                        status: 1
                    },
                    {
                        type: 'disabled',
                        label: this.$t('status.desactivated-s'),
                        icon: 'power-off',
                        status: -2,
                        alert: this.alerts.desactivated
                    },
                    {
                        type: 'deleted',
                        label: this.$t('status.supressed-s'),
                        icon: 'trash-alt',
                        status: 0,
                        alert: this.alerts.deleted
                    }
                ]
            }
        },
        created() {
            this.getConfigByName({name: 'url_download_wordpress_plugin'}).then(data => {
                this.wordpress_url_plugin = data
            })
            this.getGroups().then(() => {
                this.formatGroups()
            })
            this.getCounts().then(() => {
                this.preloading = false
            })

            this.resetForm()
        },

        methods: {
            ...mapActions('partner_sites', [
                'getSitesCount',
                'bulkDeleteSites',
                'bulkDisableSites',
                'bulkEnableSites',
                'bulkSetRedaction',
                'bulkSetWordLimit',
                'bulkSetAutoAcceptOrders',
                'bulkSetAutoAcceptPreOrders',
                'scrapSite'
            ]),
            ...mapActions('group', {
                getGroups: 'getAll'
            }),
            ...mapActions('config', ['getConfigByName']),

            formatGroups() {
                this.groups.forEach(group => {
                    this.formattedGroups.push({
                        id: group.id,
                        name: group.localized_translations.name
                    })
                    group.children.forEach(child => {
                        this.formattedGroups.push({
                            id: child.id,
                            name: child.localized_translations.name
                        })
                    })
                })
            },
            getData() {
                let siteAndWordpressData = this.axios
                    .get('/partner-sites', { index: this.statusActive })
                    .then(response => {
                        this.wordpressInformations = {
                            text: response.data.wordpress.text,
                            url_plugin: response.data.wordpress.url_plugin
                        }

                        this.sites = response.data.sites

                        this.alerts = response.data.alerts

                        let counts = response.data.counts
                        let countActive = counts.find(key => key.actif === this.statusActive)
                        let countActivation = counts.find(key => key.actif === this.statusWaitingActivation)
                        let countDesactivate = counts.find(
                            key => key.actif === this.statusDesactiveNL || key.actif === this.statusDesactivePartner
                        )
                        let countRefuse = counts.find(key => key.actif === this.statusRefuse)
                        let countDelete = counts.find(key => key.actif === this.statusDelete)
                        let countImprove = counts.find(key => key.actif === this.statusWaitingImprove)
                        this.counts = {
                            activated: countActive ? countActive.total : 0,
                            waiting_activation: countActivation ? countActivation.total : 0,
                            desactivated: countDesactivate ? countDesactivate.total : 0,
                            deleted: countDelete ? countDelete.total : 0,
                            refused: countRefuse ? countRefuse.total : 0,
                            waiting_improvement: countImprove ? countImprove.total : 0
                        }

                        this.formatTabs()
                    })
                    .catch(error => {
                        console.error(error)
                    })

                Promise.all([siteAndWordpressData]).then(() => {
                    this.preloading = false
                })
            },

            async getCounts() {
                const [all, waiting_activation, waiting_improvement, activated, disabled, deleted] = await Promise.all([
                    this.getSitesCount(),
                    this.getSitesCount({ filter: { actif: -1 } }),
                    this.getSitesCount({ filter: { actif: -5 } }),
                    this.getSitesCount({ filter: { actif: 1 } }),
                    this.getSitesCount({ filter: { actif: -2 } }),
                    this.getSitesCount({ filter: { actif: 0 } })
                ])

                this.counts = {
                    all,
                    waiting_activation,
                    waiting_improvement,
                    activated,
                    disabled,
                    deleted
                }
            },

            saveSite(formData) {
                this.isLoading = true

                let form = new FormData()
                for (const [key, value] of Object.entries(formData)) {
                    if (value === null) {
                        form.append(key, '')
                    } else {
                        form.append(key, value)
                    }
                }

                this.axios
                    .post(`/partner-sites`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        this.snackNotif(this.$t('partenaire-site.valid-save-site'))
                        this.counts.waiting_activation = this.counts.waiting_activation + 1
                        this.sites.data = [...this.sites.data, response.data.site]
                        this.sites.total = this.sites.total + 1

                        this.formatTabs()
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },
            editSite(formData) {
                this.forceUpdate = true

                let form = new FormData()
                form.append('_method', 'PATCH')

                for (const [key, value] of Object.entries(formData)) {
                    if (value === null) {
                        form.append(key, '')
                    } else {
                        form.append(key, value)
                    }
                }

                this.axios
                    .post(`/partner-sites/${formData.id}`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        this.snackNotif(this.$t('partenaire-site.valid-edit-site'))
                        this.counts.waiting_activation = this.counts.waiting_activation + 1
                        this.forceUpdate = false
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
            },
            reloadTabs() {
                this.openOnce = { [this.activeTab]: true }
                this.forceUpdate = !this.forceUpdate
            },

            deleteBulkSites(params) {
                this.isLoading = true

                this.bulkDeleteSites({
                    data: { notes: params.notes, ids: JSON.stringify(params.ids) }
                })
                    .then(() => this.snackNotif(this.$t('partenaire-site.valid-delete-site')))
                    .catch(() => this.snackNotif(this.$t('error.message.default'), 'is-danger'))
                    .finally(() => {
                        this.reloadTabs()
                        this.isLoading = false
                    })
            },
            disableBulkSites(params) {
                this.isLoading = true

                this.bulkDisableSites({
                    ids: JSON.stringify(params.ids),
                    choice_type: params.choice_type,
                    scheduled_at: params.scheduled_at,
                    desactivation_site_to: params.desactivation_site_to
                })
                    .then(() => this.snackNotif(this.$t('partenaire-site.valid-desactive-site')))
                    .catch(() => this.snackNotif(this.$t('error.message.default'), 'is-danger'))
                    .finally(() => {
                        this.reloadTabs()
                        this.isLoading = false
                    })
            },
            reactivateBulkSites(params) {
                this.isLoading = true

                this.bulkEnableSites({
                    ids: JSON.stringify(params.ids)
                })
                    .then(() => this.snackNotif(this.$t('partenaire-site.valid-reactive-site')))
                    .catch(() => this.snackNotif(this.$t('error.message.default'), 'is-danger'))
                    .finally(() => {
                        this.reloadTabs()
                        this.isLoading = false
                    })
            },
            redactionBulkSites(params) {
                this.isLoading = true

                this.bulkSetRedaction({
                    ids: JSON.stringify(params.ids),
                    redaction: params.redaction
                })
                    .then(() => this.snackNotif(this.$t('partenaire-site.valid-edit-sites')))
                    .catch(() => this.snackNotif(this.$t('error.message.default'), 'is-danger'))
                    .finally(() => {
                        this.reloadTabs()
                        this.isLoading = false
                    })
            },
            maxWordsBulkSites(params) {
                this.isLoading = true

                this.bulkSetWordLimit({
                    ids: JSON.stringify(params.ids),
                    contenu_redige_max: params.contenu_redige_max
                })
                    .then(() => this.snackNotif(this.$t('partenaire-site.valid-edit-sites')))
                    .catch(() => this.snackNotif(this.$t('error.message.default'), 'is-danger'))
                    .finally(() => {
                        this.reloadTabs()
                        this.isLoading = false
                    })
            },
            setAcceptAutoBulkSites(params) {
                this.isLoading = true

                this.bulkSetAutoAcceptOrders({
                    ids: JSON.stringify(params.ids)
                })
                    .then(() => this.snackNotif(this.$t('partenaire-site.valid-edit-sites')))
                    .catch(() => this.snackNotif(this.$t('error.message.default'), 'is-danger'))
                    .finally(() => {
                        this.reloadTabs()
                        this.isLoading = false
                    })
            },
            setAcceptAutoPreorderBulkSites(params) {
                this.isLoading = true

                this.bulkSetAutoAcceptPreOrders({
                    ids: JSON.stringify(params.ids)
                })
                    .then(() => this.snackNotif(this.$t('partenaire-site.valid-edit-sites')))
                    .catch(() => this.snackNotif(this.$t('error.message.default'), 'is-danger'))
                    .finally(() => {
                        this.reloadTabs()
                        this.isLoading = false
                    })
            },

            formatTabs() {
                this.isLoading = false
            },

            openModal() {
                this.$refs.siteModal.openModal()
            },

            openModalFromTable(site) {
                this.$refs.siteModal.openModal(site)
            },

            scrap(site) {
                this.isLoading = true

                this.scrapSite(site.id)
                    .then(() => this.snackNotif(this.$t('partenaire-site.valid-edit-sites')))
                    .catch(() => this.snackNotif(this.$t('error.message.default'), 'is-danger'))
                    .finally(() => {
                        this.reloadTabs()
                        this.isLoading = false
                    })
            },

            resetForm() {
                this.form = {
                    url: '',
                    lang: 'fr_fr',
                    type_cms: 'wordpress',
                    group_id: 1,
                    redaction: 'nextlevel',
                    validation: 'simple',
                    scrape_sitemap: '',
                    scrape_whitelist: [],
                    scrape_blacklist: [],
                    refus_concurrent_mfa: 'non',
                    lien_secondaire_autorise: 1,
                    contenu_redige_max: 700,
                    ancres_acceptees: 'toutes',
                    planification_autorisee: 1,
                    prix_minimum_filtre: 0,
                    clear_whitelist: false,
                    clear_blacklist: false,
                    nb_keywords: 0,
                    display_urls: true
                }
            }
        }
    }
</script>
